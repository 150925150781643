<template>
  <div class="err" style>
    <Header />
    <img src="../assets/img/404.svg">
  </div>
</template>
<script>
import Header from '../components/Header'
export default {
  components: {
    Header
  }
}
</script>
<style scoped>
.err {
  margin-top: 60px;
  text-align: center;
}
</style>
